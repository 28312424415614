import React from "react";
import "../../assets/css/Footer.css"; // Make sure to create and link the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      {/* Subscribe Section */}
      <div className="footer-subscribe">
        <h2 className="subscribe-title">
          Subscribe To Our Newsletter
        </h2>
        <div className="subscribe-form">
          <input
            type="email"
            placeholder="Email"
            className="subscribe-input"
          />
          <button className="subscribe-button">Subscribe</button>
        </div>
      </div>

      {/* Footer Content */}
      <div className="footer-content">
        {/* Logo and Social Links */}
        <div className="footer-section footer-logo">
          <img
            src="/images/new-website/bhlogo.webp"
            alt="BridgeHealth Logo"
            className="footer-logo-img"
          />
          <div className="footer-social">
            <a href="https://www.facebook.com/bridgehealthgroup" aria-label="Facebook">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://x.com/Bridgehealthin" aria-label="Twitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://x.com/Bridgehealthin" aria-label="LinkedIn">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/bridgehealth.in" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/@bridgehealthin" aria-label="Instagram">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>

        {/* Quick Links */}
        <div className="footer-section">
          <h3 className="footer-heading">Quick Links</h3>
          <ul className="footer-links">
            <li>
              <a href="/about-company">About Us</a>
            </li>
            <li>
              <a href="/blogs">Blogs</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/privacy-policy">Policy</a>
            </li>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="footer-section">
          <h3 className="footer-heading">Contact Info</h3>
          <p>
            <i className="fas fa-map-marker-alt"></i> No.166-167, 21st Main
            Road, 100 Feet Ring Road, 2nd Phase, JP Nagar, Bangalore - 560078
          </p>
          <p>
            <i className="fas fa-phone"></i> +91 8047093866
          </p>
          <p>
            <i className="fas fa-envelope"></i>{" "}
            <a href="mailto:info@bridgehealthgroup.com">
              info@bridgehealthgroup.com
            </a>
          </p>
          <div className="footer-apps">
            <span>Download Bridge Health App</span>
            <a href="https://play.google.com/store/apps/details?id=com.bridgehealthgroup.bridgehealth">
              
              <img
                src="/images/google-play-store-icon.webp"
                alt="Play Store"
                className="footer-app-icon"
              />
              </a>
              <a href="https://apps.apple.com/in/app/bridge-health/id1670868217">
              <img
                src="/images/app-store-icon.png"
                alt="App Store"
                className="footer-app-icon"
              />
              </a>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="footer-bottom">
        &copy; 2025 Bridgehealth. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
