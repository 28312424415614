import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/header-new";
import SliderLab from "../element/component/Banner_slider";
import ServiceSection from "../element/component/serviceSectionDiet";
import FAQComponent from '../element/component/faq';
import WhyLab from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const YogaPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const Counter = ({ targetValue, duration }) => {
    const [count, setCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      let startValue = 0;
      const increment = targetValue / (duration / 50);
  
      const updateCounter = () => {
        startValue += increment;
        if (startValue >= targetValue) {
          setCount(Math.ceil(targetValue));
          setIsAnimating(false); // End animation
        } else {
          setCount(Math.floor(startValue));
          setIsAnimating(true); // Start animation
          requestAnimationFrame(updateCounter);
        }
      };
  
      setIsAnimating(true); // Initialize animation
      updateCounter();
    }, [targetValue, duration]);
  
    return <h3 className={isAnimating ? "animate-counter" : ""}>{count}%</h3>;
  };

  const [activeIndex, setActiveIndex] = useState(0);
  
  const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
   };

  const cardsData = [
    { title: "Diet Program for Diabetes Management", image: "/images/sugar-blood-test.webp" },
    { title: "Diet Program for Weight Loss/Weight Gain ", image: "/images/new-website/yoga/obesity.webp" },
    { title: "Gut Health Diet Plan", image: "/images/new-website/yoga/digestive-health.webp" },
    { title: "Heart Health/High Cholesterol Diet Plan", image: "/images/new-website/yoga/healthy-heart.webp" },
    { title: "Diet Program for PCOS ", image: "/images/new-website/yoga/women-health.webp" },
    { title: "Pregnancy Diet Plan", image: "/images/new-website/yoga/lifestyle-yoga.webp" },
  ];
  
  const whyData = [
    { title: "Nutrition Insights & Personalised Diet Plan", image: "/images/new-website/diet-nutrition/nutrition.webp" },
    { title: "AI enabled CGM supported program", image: "/images/new-website/diet-nutrition/artificial-intelligence.webp" },
    { title: "Professional, Highly experienced Dieticians", image: "/images/new-website/diet-nutrition/dietician.png" },
    { title: "For better Weight-Gain/Loss management program", image: "/images/new-website/diet-nutrition/weight-scale.webp" },
    { title: "Reduced Stress and Improved Sleep", image: "/images/new-website/diet-nutrition/stress-relief.webp" },
    { title: "Become a Healthier & Fitter You", image: "/images/new-website/diet-nutrition/slim-body.webp" },
];

const options = [
    {
      title: "Personalised Nutrition Plans",
      content: "Every plan is crafted to match your lifestyle and health goals, ensuring sustainable results."
    },
    {
      title: "Expert Guidance Anytime, Anywhere",
      content: "Access the best dietician in Bangalore for weight loss or consult an online dietician near you for 24/7 support."
    },
    {
      title: "Holistic Tech Aided Focus on Wellness",
      content: "Our approach combines balanced nutrition with practical strategies, and advanced technology for long-term health success."
    },
  ];

// Slider settings defined here
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "15%",
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: (
        <div className="slick-prev">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        ),
        nextArrow: (
        <div className="slick-next">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        ),
        responsive: [
          {
            breakpoint: 1024, // Tablet view
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Mobile view
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };

    const slides = [
        {
          desktop: "/images/new-website/yoga/yoga-center-5.webp",
          mobile: "/images/new-website/yoga/yoga-center-5.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-center-6.webp",
          mobile: "/images/new-website/yoga/yoga-center-6.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-center-7.webp",
          mobile: "/images/new-website/yoga/yoga-center-7.webp",
        },
        {
            desktop: "/images/new-website/yoga/yoga-center-6.webp",
            mobile: "/images/new-website/yoga/yoga-center-6.webp",
        },
      ];

    const yogaTypes = [
        {
            name: "Group School",
            desc: "Join our dynamic group sessions, including Hatha Yoga and Vinyasa Yoga, designed to foster community and support.",
            image: "/images/new-website/yoga/yoga-type-1.webp",
          },
          {
            name: "Individual Classes",
            desc: "Whether it's to deepen your yoga practice or focus on specific goals, seek one-on-one attention and a customised approach. ",
            image: "/images/new-website/yoga/yoga-type-2.webp",
          },
          {
            name: "Online Classes",
            desc: "If you're searching for yoga near me but prefer the convenience and privacy of practing at home, look no further! ",
            image: "/images/new-website/yoga/yoga-type-3.webp",
          },
    
      ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What is the best Diet for Weight Loss in Bangalore? ",
      answer:
        "A balanced diet with local, seasonal foods like millets, vegetables, lean proteins, and controlled portions can support sustainable weight loss. ",
    },
    {
      question: "How can I improve my Gut Health with Diet? ",
      answer: "Eat a fibre-rich diet including fruits, vegetables, whole grains, and fermented foods like yogurt or idli to promote healthy gut bacteria. ",
    },
    {
      question: "What is Personalised Nutrition and How It Works? ",
      answer: "Personalised nutrition tailors dietary recommendations based on individual factors like genetics, health goals, and lifestyle for better health outcomes. ",
    },
    {
      question: "Can I manage my Cholesterol through Diet? ",
      answer: "Yes, consuming foods rich in fibre, healthy fats (like nuts and fish), and avoiding trans fats can effectively lower cholesterol levels.",
    },
    {
      question: "WWhat is the cost of a Diet Coach in Bangalore? ",
      answer: "The cost of a diet coach in Bangalore ranges from ₹1,500 to ₹5,000 per session, depending on the coach's experience and expertise. ",
    },
  ];


  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards
  const [hasAnimated, setHasAnimated] = useState([]); // Animation tracking for cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

      setHasAnimated((prev) =>
        updatedVisibility.map((visible, index) => prev[index] || visible)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const styles = {
    headingwoman1: {
        fontSize: "50px",
        color:"#0d0d0d",
        fontWeight: "600",
        lineHeight: "1.2",
        textAlign: "left",
        width: "100%"
      }
      
  };

  const nablslides = [
    "/images/Bridge-Health-ECG-1.webp",
    "/images/Bridge-Health-Labs-1.webp",
    "/images/BH_lab_closeup-1.webp",
  ];

  const tests = [
    {
      icon: "/images/blood-test.webp",
      title: "Complete Blood Count (CBC)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/sugar-blood-test.webp",
      title: "HbA1c, Glycated Haemoglobin",
      testsIncluded: "3 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
  ];

  return (
    <div>
      <Header/>
      <div style={{marginTop:"50px"}} className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1>
          Let The  {" "}
            <span className="highlight1">Best Dietitians</span> in Bangalore Guide You to Better Health
          </h1>
          <p className="paragraph-left">Begin Your Wellness Journey with Bridge Health’s Personalised Nutrition Plans Crafted by Expert Nutritionists in Bangalore.  </p>
          <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Get Started Today</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/diet-nutrition/diet-nutrition-landing.webp"
            alt="yoga-landing"
            className="imagelanding imageFadeInUp"
          />
        </div>
      </div>
      
            <section>
            <div className="rightstrongcontainer">
            <h2 className="heading1">
            Find Your
              <span className="highlight1"> Perfect Diet Plan</span>
              <span className="line-break-heading"></span> – Tailored Just for You! 
            </h2>
            </div>
              <ServiceSection
                cardsData={cardsData}
                isMobile={isMobile}
                highlight="For All Your Health Needs"
                heading="Personalised Offline & Online Yoga Classes"
                customClass="WellnessSectionWomen"
              />
              <div className="service-section-button">
                <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
              </div>
            </section>
            
      <section>
        <div className="physio-options">
          <div className="physio-options-container">
            <h2 className="heading1-left">
            Transform Your Eating Habits with Bridge Health’s
              <span className="highlight1"> Proven Dietary Approach</span>
              <span className="line-break-heading"></span>
            </h2>
            <p>Transform your eating habits with Bridge Health’s expert dietary approach. Our proven methods ensure personalized nutrition plans tailored to your goals. Achieve a healthier lifestyle with guidance you can trust.</p>
            
            <button className="buttonmaphome" onClick={()=>setIsOpen(true)}>Request a Call Back</button>

          </div>
          <div className="physio-option-right">
            <div className="accordion-container">
                {options.map((option, index) => (
                    <div key={index} className="accordion-item">
                    <button
                        className={`accordion-title-new ${activeIndex === index ? "active" : ""}`}
                        onClick={() => toggleAccordion(index)}
                    >
                        {option.title}
                    </button>
                    {activeIndex === index && (
                        <div className="accordion-content-desc">
                        {option.content}
                        </div>
                    )}
                    </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <section>
          <div className="expert-nutrition-container">
              {/* Left Column - Image */}
              <div className="expert-nutrition-left">
                <img
                  src="/images/new-website/diet-nutrition/nutrition-expert.webp"
                  alt="Health Journey"
                  className="expert-nutrition-image"
                />
              </div>

              {/* Right Column - Text */}
              <div className="expert-nutrition-right">
                <h1 className="heading1-left">
                Achieve Your Wellness Goals with Bridge Health’s {" "}
                  <span className="highlight1">
                  Expert Nutrition Programs
                  </span>{" "}
                </h1>
                <ul class="nutrition-list">
                    <li>Get Access to Personalised Diet Plans Backed by Nutrition Insights </li>
                    <li>Stay ahead of your health with cutting-edge technology AI-Enabled CGM (Continuous Glucose Monitoring) </li>
                    <li>Work with professional, highly experienced dieticians in JP Nagar in Bangalore for the best results </li>
                    <li>Achieve your ideal weight with science-backed strategies & effective weight-gain and weight-loss solutions </li>
                    <li>Our holistic programs help you feel calmer, sleep better, and reduce your stress</li>
                    <li>Build sustainable habits and enjoy a vibrant, energised version of yourself </li>
                </ul>
                <div style={{marginTop: "30px"}} className="button-container">
                  <button className="buttonjourney" onClick={()=>setIsOpen(true)}>Reach out to us today</button>
                </div>
              </div>
            </div>
        </section>

        <section className="why-choose-section-physio">
          <h2 className="heading1">Why Choose <span className="highlight1">Bridge Health</span> for Physiotherapy</h2>
                    
          <WhyLab
            whyData={whyData} 
            dynamicText="Radiology" 
            dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
          />
      </section>

    <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
    <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
        <Footer />
    </div>
  );
};

export default YogaPage;
