import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/index";
import HomeNew from "./pages/homeNewUpdatedFinal";
// import blog from "./pages/blog";
import Team from "./pages/team";
import BackToTop from "./layout/backToTop";
import corporate_membership_program from "./pages/corporate-membership-program";
import b2cmembership from "./pages/personal-membership-program";
import ourservice from "./pages/ourservice";
import diet_and_nutrition from "./pages/diet-and-nutrition";
import yoga_Assessment from "./pages/yoga-Assessment";
import lab_tests from "./pages/lab-tests";
import physiotherapy from "./pages/physiotherapy";
import pharmacy from "./pages/pharmacy";
import psychological_Health from "./pages/psychological-Health";
import contact from "./pages/contact-us";
import ophthalmologist from "./pages/ophthalmologists";
import dermatology from "./pages/dermatology";
import doctor_consults from "./pages/doctor-consults";
import dental from "./pages/dental";
import fitnessAssessment from "./pages/fitness-assessment";
import nursingAtHome from "./pages/nursing-at-home";
import comprehensiveHealth from "./pages/comprehensive-health";
import elderlyCare from "./pages/elderly-care";
import diabetes from "./pages/diabetes";
import manageWeight from "./pages/manage-weight";
import hypertension from "./pages/hypertension";
import aboutUs from "./pages/aboutus";
import Career from "./pages/career";
import FAQ from "./pages/faq-page";
import affiliatePartners from "./pages/affiliate-partners";
import HRA from "./pages/hra-page";
import deviceAndTechnology from "./pages/device-and-technology";
import MediaAndPR from "./pages/media-and-pr";
import termsOfUse from "./pages/terms-of-use";
import privacyPolicies from "./pages/privacy-policies";
import FormComponent from "./pages/career-form";
import careerAdmin from "./pages/career-admin";
import JobDescription from "./pages/JobDescription";
import LoginForm from "./pages/LoginForm";
import JobListingForm from "./pages/JobListingForm";
import Sidenav from "./pages/Sidenav";
import shingles from "./pages/shingles";
import vaccination from "./pages/vaccination";
import blog_designs from "./pages/blog-designs";
import SingleBlog from "./pages/blog-inner";
import blog_shingles from "./pages/blog-shingles";
import blog_list from "./pages/blog";
import blog_shingles_prevention from "./pages/blog-shingles-prevention";
// import blog_designs_copy from "./pages/blog-designs-copy";
import Affiliate from "./pages/affiliate";
import covid from "./pages/covid";
import influenza from "./pages/influenza";
import hepatitis from "./pages/hepatitis";
import hpv from "./pages/hpv";
import hpv_vaccines from "./pages/hpv-vaccines";
import Thankyou from "./pages/thankyou";
import explore from "./pages/explore";
import leadGeneration from "./pages/leadGeneration";
import Leadership from "./pages/leadership";
import LeadClinical from "./pages/lead-clinical";
import ShinglesPopup from "./element/shinglesPopup";
import Testimonial1 from "./element/testimonial1";
import IndianMapWithPointers from "./pages/IndianMap";
import TestPage from "./pages/test_page";
import Homenew1 from "./pages/homeNewUpdatedFinal";
import WomenCare from "./pages/womencare";
import ElderCare from "./pages/eldercare";
import AboutCompany from "./pages/aboutUsNew";
import LabTestNew from "./pages/LabTest";
import Radiology from "./pages/radiology";
import AISmart from "./pages/smartScreenings";
import Yoga from "./pages/yoga";
import PhysiotherapyNew from "./pages/physiotherapyNew";
import DietNutrition from "./pages/dietNutrition";
import AdultVaccines from "./pages/adultVaccines";
import ContactNew from "./pages/contactNew";


class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="page-wrapper">
          <Switch>
            <Route path="/home-old" exact component={Index} />
            <Route path="/home-new" exact component={HomeNew}/>
            <Route path="/contact-us" exact component={contact}/>
            {/* <Route path="/blog" component={blog} /> */}
            <Route path="/leadership" component={Leadership}/>
            <Route path="/team" component={Team} />
            <Route
              path="/corporate-membership-program"
              component={corporate_membership_program}
            />
            <Route path="/career-form" component={FormComponent}/>
            <Route path="/our-services" component={ourservice}/>
            <Route
              path="/personal-membership-program"
              component={b2cmembership}
            />
            <Route path="/diet-and-nutrition" component={diet_and_nutrition}/>
            <Route path="/yoga-assessment" component={yoga_Assessment} />
            <Route path="/lab-tests-new" component={lab_tests}/>
            <Route path="/physio-therapy" component={physiotherapy}/>
            <Route path="/pharmacy" component={pharmacy}/>
            <Route
              path="/psychological-health"
              component={psychological_Health}
            />
            <Route path="/ophthalmologist" component={ophthalmologist}/>
            <Route path="/dermatology" component={dermatology}/>
            <Route path="/doctor-consults" component={doctor_consults}/>
            <Route path="/dental-consults" component={dental} />
            <Route path="/fitness-assessment" component={fitnessAssessment}/>
            <Route path="/nursing-at-home" component={nursingAtHome}/>
            <Route
              path="/comprehensive-health-checks"
              component={comprehensiveHealth}
            />
            <Route path="/elderly-care" component={elderlyCare} />
            <Route path="/diabetes" component={diabetes} />
            <Route path="/manage-weight" component={manageWeight} />
            <Route path="/hyper-tension" component={hypertension} />
            <Route path="/about-us" component={aboutUs} />
            <Route path="/career" component={Career} />
            <Route path="/faq" component={FAQ} />
            <Route path="/affiliate-partners" component={affiliatePartners} />
            <Route path="/hra" component={HRA} />
            <Route
              path="/devices-and-technology"
              component={deviceAndTechnology}
            />
            <Route path="/media-and-pr" component={MediaAndPR} />
            <Route path="/terms-of-use" component={termsOfUse} />
            <Route path="/privacy-policy" component={privacyPolicies} />
            <Route path="/career-admin" component={careerAdmin} />
            <Route path="/job-description/:id" component={JobDescription} />
            <Route path="/LoginForm" component={LoginForm} />
            <Route path="/sidenav" component={Sidenav} />
            <Route path="/JobListingForm" component={JobListingForm} />
            <Route path="/shingles" component={shingles} />
            <Route path="/vaccination" component={vaccination} />
            {/* <Route
              path="/the-influence-of-sleep-on-mental-health"
              component={blog_designs}
            /> */}
            <Route
              path="/shingles-101-causes-symptoms-diagnosis-and-treatment"
              component={blog_shingles}
            />
            <Route
              path="/shield-yourself-from-shingles"
              component={blog_shingles_prevention}
            />
            {/* <Route
              path="/blog-designs-copy/:postId"
              component={blog_designs_copy}
            /> */}
            <Route path="/affiliate" component={Affiliate} />
            <Route path="/covid" component={covid} />
            <Route path="/influenza" component={influenza} />
            <Route path="/hepatitis" component={hepatitis} />
            <Route path="/hpv" component={hpv} />
            <Route path="/hpv-vaccines" component={hpv_vaccines} />
            <Route path="/thankyou" component={Thankyou} />
            <Route path="/explore" component={explore} />
            <Route path="/physiotherapy" component={physiotherapy} />
            <Route
              path="/comprehensive-health"
              component={comprehensiveHealth}
            />
            <Route
              path="/blog-shingles-prevention"
              component={blog_shingles_prevention}
            />
            <Route path="/lead-generation-form" component={leadGeneration}/>
            <Route path="/lead-clinical" component={LeadClinical} />
            <Route path="/blog-design" component={blog_designs} />
            {/* <Route exact path="/blog/:Slug" component={SingleBlog} /> */}
            <Route path="/blogs/:Slug" component={SingleBlog} />
            <Route path="/blogs" component={blog_list} />
            <Route path="/shingles-popup" component={ShinglesPopup} />
            <Route path="/testimonial1" component={Testimonial1} />
            <Route path="/IndianMap" component={IndianMapWithPointers} />
            <Route path="/test" component={TestPage} />
            <Route path="/" component={Homenew1} />
            <Route path="/women-health" component={WomenCare} />
            <Route path="/elder-care" component={ElderCare} />
            <Route path="/about-company" component={AboutCompany} />
            <Route path="/lab-tests" component={LabTestNew} />
            <Route path="/radiology" component={Radiology} />
            <Route path="/ai-smart-screening" component={AISmart} />
            <Route path="/yoga" component={Yoga} />
            <Route path="/physiotherapy-services" component={PhysiotherapyNew} />
            <Route path="/diet-and-nutrition-program" component={DietNutrition} />
            <Route path="/adult-vaccination-program" component={AdultVaccines} />
            <Route path="/contact" component={ContactNew} />

            IndianMap
          </Switch>
          <BackToTop />
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
