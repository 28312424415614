import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/header-new";
import SliderLab from "../element/component/Banner_New_Slider";
import ServiceSectionLeft from "../element/component/ServiceSectionPhysioLeft";
import ServiceSectionRight from "../element/component/ServiceSectionPhysioRight";
import FAQComponent from '../element/component/faq';
import WhyLab from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const Physiotherapy = () => {

    const [activeTab, setActiveTab] = useState("jpnagar");
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const options = [
      {
        title: "Individual Offline Therapy",
        content: "Personalised sessions focusing on clinical rehabilitation, women’s health, and geriatric care for tailored recovery. "
      },
      {
        title: "Group Offline Therapy",
        content: "Interactive group sessions designed to promote mobility and strength in a supportive environment. "
      },
      {
        title: "Online and At-Home Therapy",
        content: "Convenient individual or group sessions delivered online or at home for flexibility and comfort "
      },
    ];

  const Counter = ({ targetValue, duration }) => {
    const [count, setCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      let startValue = 0;
      const increment = targetValue / (duration / 50);
  
      const updateCounter = () => {
        startValue += increment;
        if (startValue >= targetValue) {
          setCount(Math.ceil(targetValue));
          setIsAnimating(false); // End animation
        } else {
          setCount(Math.floor(startValue));
          setIsAnimating(true); // Start animation
          requestAnimationFrame(updateCounter);
        }
      };
  
      setIsAnimating(true); // Initialize animation
      updateCounter();
    }, [targetValue, duration]);
  
    return <h3 className={isAnimating ? "animate-counter" : ""}>{count}%</h3>;
  };
  
  const whyData = [
    { title: "Certified and Experienced Physiotherapists", image: "/images/new-website/physiotherapy/physiotherapist.webp" },
    { title: "Tailored Treatment Plans", image: "/images/new-website/yoga/personalized-treatment.webp" },
    { title: "Holistic Approach", image: "/images/new-website/yoga/comprehensive-holistic-approach.webp" },
    { title: "Wide Range of Care", image: "/images/new-website/physiotherapy/wide-range-care.webp" },
    { title: "Flexible Schedules, Affordable Plans", image: "/images/new-website/yoga/flexible-schedule.png" },
    { title: "State-of-the-Art Equipment", image: "/images/new-website/physiotherapy/physio-equipments.webp" },
];

// Slider settings defined here
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "15%",
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: (
        <div className="slick-prev">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        ),
        nextArrow: (
        <div className="slick-next">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        ),
        responsive: [
          {
            breakpoint: 1024, // Tablet view
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Mobile view
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };

    const slides = [
        {
          desktop: "/images/new-website/yoga/yoga-center-5.webp",
          mobile: "/images/new-website/yoga/yoga-center-5.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-center-6.webp",
          mobile: "/images/new-website/yoga/yoga-center-6.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-center-7.webp",
          mobile: "/images/new-website/yoga/yoga-center-7.webp",
        },
        {
            desktop: "/images/new-website/yoga/yoga-center-6.webp",
            mobile: "/images/new-website/yoga/yoga-center-6.webp",
        },
      ];

    const yogaTypes = [
        {
            name: "Pain Management & Post Surgical Rehabilitation",
            desc: "Physiotherapy to reduce pain and support recovery after surgery for improved mobility and function. ",
            image: "/images/new-website/physiotherapy/physiotherapy-clinic.webp",
          },
          {
            name: "Physiotherapy for Sports Injury Recovery",
            desc: "Targeted physiotherapy treatment to heal sports-related injuries and restore peak performance. ",
            image: "/images/new-website/physiotherapy/digital-physiotherapy.webp",
          },
          {
            name: "Chronic Condition Management",
            desc: "Ongoing care to manage and alleviate symptoms of long-term health issues.",
            image: "/images/new-website/physiotherapy/chronic-condition.webp",
          },
          {
            name: "Physiotherapy for Women’s Health",
            desc: "Specialised treatments addressing the unique health needs of women.",
            image: "/images/new-website/physiotherapy/physiotherapy-home.webp",
          },
    
      ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What are the benefits of physiotherapy? ",
      answer:
        "Benefits of Physiotherapy are: It helps reduce pain, improve mobility, strengthen muscles, and restore functional independence after injury or surgery. ",
    },
    {
      question: "Does Bridge Health provide home services for physiotherapy? ",
      answer: "Yes, we provide home services for physiotherapy, and group therapy sessions, both offline and online. ",
    },
    {
      question: "What types of physiotherapy services does Bridge Health offer in Bangalore?",
      answer: "Bridge Health offers services like pain management, post-surgical rehabilitation, sports injury recovery, physiotherapy for chronic conditions, dry needling, posture & gait training, exercise therapy and a whole lot more for pregnant women and the elderly. ",
    },
    {
      question: "Does Bridge Health offer physiotherapy for elderly patients in Bangalore? ",
      answer: "Yes, Bridge Health provides specialised physiotherapy services tailored for elderly patients to improve mobility, strength, and quality of life, and prevent falls.",
    },
    {
      question: "What are the costs of physiotherapy sessions at Bridge Health in Bangalore? ",
      answer: "The cost of physiotherapy sessions at Bridge Health varies depending on the type of therapy and duration; detailed pricing is available upon consultation",
    },
  ];


  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards
  const [hasAnimated, setHasAnimated] = useState([]); // Animation tracking for cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

      setHasAnimated((prev) =>
        updatedVisibility.map((visible, index) => prev[index] || visible)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardsData = [
    { title: "Nech and Shoulder Pain ", image: "/images/new-website/physiotherapy/neck.webp" },
    { title: "Back Pain and Slip Disc ", image: "/images/new-website/physiotherapy/back-neuro.webp" },
    { title: "Exercises for Women’s Health ", image: "/images/new-website/physiotherapy/prenatal-care.webp" },
    { title: "Ergonomic Exercises ", image: "/images/new-website/physiotherapy/computer-desktop.webp" },
  ];

  const cardsDataRight = [
    { title: "Post –Paralysis & Rehabilitation ", image: "/images/new-website/physiotherapy/technology-1.webp" },
    { title: "Neuro Physiotherapy ", image: "/images/new-website/physiotherapy/chiropractic.webp" },
    { title: "Hip & Knee Replacement Rehabilitation ", image: "/images/new-website/physiotherapy/broken-bone.webp" },
    { title: "Geriatric Physiotherapy ", image: "/images/new-website/physiotherapy/technology.webp" },
  ];

  const styles = {
    headingwoman1: {
        fontSize: "50px",
        color:"#0d0d0d",
        fontWeight: "600",
        lineHeight: "1.2",
        textAlign: "left",
        width: "100%"
      }
      
  };

  const nablslides = [
    "/images/Bridge-Health-ECG-1.webp",
    "/images/Bridge-Health-Labs-1.webp",
    "/images/BH_lab_closeup-1.webp",
  ];

  const tests = [
    {
      icon: "/images/blood-test.webp",
      title: "Complete Blood Count (CBC)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/sugar-blood-test.webp",
      title: "HbA1c, Glycated Haemoglobin",
      testsIncluded: "3 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
  ];

  return (
    <div>
      <Header/>
      <div style={{marginTop:"100px"}} className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainerphysio ${isMobile ? "mobile" : ""}`}>
          <h1>
          Physiotherapy for {" "}
            <span className="highlight1"> Pain-Free Living:</span> New Beginning with Bridge Health 
          </h1>
          <p className="paragraph">Our certified physiotherapists deliver tailored treatments to relieve pain, restore function, and boost mobility.  </p>
          <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Book Your Free Consultation</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/physiotherapy/physiotherapy-landing.webp"
            alt="physio-landing"
            className="physiolanding imageFadeInUp"
          />
        </div>
      </div>
      
      <section>
            <div style={{marginTop:"50px"}} className="rightstrongcontainer">
            <h2 className="heading1">
            Explore
              <span className="highlight1"> Expert Therapies </span>
              <span className="line-break-heading"></span>  for A Wide Range of Conditions
            </h2>
            <p style={{textAlign:"center"}}>Individual therapies crafted to take care of all your health needs. </p>
                    <div className="physio-service">
                    <ServiceSectionLeft
                            cardsData={cardsData}
                            isMobile={isMobile}
                            highlight="For All Your Health Needs"
                            heading="Personalised Offline & Online Yoga Classes"
                            customClass="WellnessSectionWomen"
                    />
                    <div className="skeleton-container">
                            <div className="hover-area" data-target="neck"></div>
                            <div className="hover-area" data-target="back"></div>
                            <div className="hover-area" data-target="post-paralysis"></div>
                            <div className="hover-area" data-target="neuro"></div>
                            <div className="hover-area" data-target="hip-knee"></div>
                            <img
                            src="/images/new-website/physiotherapy/skeleton.webp"
                            alt="Skeleton"
                            className="physio-skeleton"
                            />
                        </div>
                    <ServiceSectionRight
                        cardsDataRight={cardsDataRight}
                        isMobile={isMobile}
                        highlight="For All Your Health Needs"
                        heading="Personalised Offline & Online Yoga Classes"
                        customClass="WellnessSectionWomen"
                    />
              </div>
              <div className="service-section-button">
                <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
              </div>
              </div>
      </section>
            
      <section>
        <div className="yoga-types">
          <div className="rightstrongcontainer">
            <h2 className="heading1">
            Types of
              <span className="highlight1"> Physiotherapy Services</span>
              <span className="line-break-heading"><br /></span> at Bridge Health
            </h2>
          </div>
          <div className="yoga-type-card-section">
                {yogaTypes.map((yoga, index) => (
                    <div
                    key={index}
                    className="physio-type-card"
                    >
                    <img src={yoga.image} alt={yoga.name} className="leadership-image" />
                    <div className="physio-types-desc">
                    <h3 className="solutiontext"><span className="solutionbold">{yoga.name.split(" ")[0]}</span>{" "}
                    {yoga.name.split(" ").slice(1).join(" ")}</h3>
                    <p className="yoga-type-desc">{yoga.desc}</p>
                    </div>
                    </div>
                ))}
          </div>
        </div>
      </section>

      <section>
        <div className="physio-options">
          <div className="physio-options-container">
            <h2 className="heading1-left">
            Bridge Health's
              <span className="highlight1"> Physiotherapy Treatment</span>
              <span className="line-break-heading"></span> Options to Choose From 
            </h2>
            <p>Discover flexible physiotherapy services tailored to your lifestyle. Choose from Individual Offline Therapy, Group Offline Therapy, or convenient Online and At-Home Therapy. Each option ensures expert-guided treatment for your recovery and well-being.</p>
            
            <button className="buttonmaphome" onClick={()=>setIsOpen(true)}>Request a Call Back</button>

          </div>
          <div className="physio-option-right">
            <div className="accordion-container">
                {options.map((option, index) => (
                    <div key={index} className="accordion-item">
                    <button
                        className={`accordion-title-new ${activeIndex === index ? "active" : ""}`}
                        onClick={() => toggleAccordion(index)}
                    >
                        {option.title}
                    </button>
                    {activeIndex === index && (
                        <div className="accordion-content-desc">
                        {option.content}
                        </div>
                    )}
                    </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <div className="clinic-pharma">
            <h2 className="heading1">Let’s Walk Through Bridge Health’s <span className="highlight1"> Physiotherapy Clinics</span> in Bangalore</h2>
            <div className="bridge-health-app"> 
            <div className="bridge-health-sidebar">
                <div
                className={`bridge-health-tab ${
                    activeTab === "jpnagar" ? "bridge-health-active-tab" : ""
                }`}
                onClick={() => setActiveTab("jpnagar")}
                >

                Bridge Health - Physiotherapy Clinic at JP Nagar
                </div>
                <div
                className={`bridge-health-tab ${
                    activeTab === "sarjapur" ? "bridge-health-active-tab" : ""
                }`}
                onClick={() => setActiveTab("sarjapur")}
                >
  
                Bridge Health - Physiotherapy Clinic at Sarjapur
                </div>
                <div
                className={`bridge-health-tab ${
                    activeTab === "jayanagar" ? "bridge-health-active-tab" : ""
                }`}
                onClick={() => setActiveTab("jayanagar")}
                >

                Bridge Health - Physiotherapy Clinic at Jayanagar
                </div>
                <div className="care-center-desk">
                <button className="buttonmaphome" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
                </div>
            </div>
            <div className="bridge-health-content">
                {activeTab === "jpnagar" && (
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=Bridgehealth%20clinics&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title="Bridgehealth clinics"
                    aria-label="Bridgehealth clinics"
                ></iframe>
                )}
                {activeTab === "sarjapur" && (
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=Bridgehealth%20pharmacy&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title="Bridgehealth pharmacy"
                    aria-label="Bridgehealth pharmacy"
                ></iframe>
                )}
                {activeTab === "jayanagar" && (
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=Bridgehealth%20pharmacy&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                    title="Bridgehealth pharmacy"
                    aria-label="Bridgehealth pharmacy"
                ></iframe>
                )}
                <div className="care-center-mob">
                <button className="buttonmaphome-mob">Request a Call Back</button>
                </div>
            </div>
            </div>
        </div>

      <section className="why-choose-section-physio">
          <h2 className="heading1">Why Choose <span className="highlight1">Bridge Health</span> for Physiotherapy</h2>
                    
          <WhyLab
            whyData={whyData} 
            dynamicText="Radiology" 
            dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
          />
      </section>

     <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
     </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
    <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
        <Footer />
    </div>
  );
};

export default Physiotherapy;
