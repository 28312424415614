import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/header-new";
import SliderLab from "../element/component/Banner_New_Slider";
import ServiceSection from "../element/component/ServiceSectionAll";
import FAQComponent from '../element/component/faq';
import WhyLab from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import BloodTestSlider from "../element/component/BloodTestSlider";

const LabTestPage = () => {

  const Counter = ({ targetValue, duration }) => {
    const [count, setCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      let startValue = 0;
      const increment = targetValue / (duration / 50);
  
      const updateCounter = () => {
        startValue += increment;
        if (startValue >= targetValue) {
          setCount(Math.ceil(targetValue));
          setIsAnimating(false); // End animation
        } else {
          setCount(Math.floor(startValue));
          setIsAnimating(true); // Start animation
          requestAnimationFrame(updateCounter);
        }
      };
  
      setIsAnimating(true); // Initialize animation
      updateCounter();
    }, [targetValue, duration]);
  
    return <h3 className={isAnimating ? "animate-counter" : ""}>{count}%</h3>;
  };
  
  const whyData = [
    { title: "NABL & ISO Certified Lab", image: "/images/new-website/radiology/radiologist.webp" },
    { title: "Expert Pathologist and Technicians", image: "/images/new-website/radiology/mri.webp" },
    { title: "Commitment to Accuracy and Confidentiality", image: "/images/new-website/radiology/accuracy.webp" },
  ];

// Slider settings defined here
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "15%",
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: (
        <div className="slick-prev">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        ),
        nextArrow: (
        <div className="slick-next">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        ),
        responsive: [
          {
            breakpoint: 1024, // Tablet view
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Mobile view
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };

    const BloodTestsettings = {
      dots: true, 
      infinite: true, 
      speed: 7000, 
      slidesToShow: 3, 
      slidesToScroll: 1,
      autoplay: true, 
      // centerMode: true,
      // centerPadding: "15%",
      autoplaySpeed: 3000, 
      responsive: [
  
        {
          breakpoint: 768, // Mobile view
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,

          },
        },
      ],
    };

    // Custom slides for this page
  const slides = [
    {
      desktop: "/images/new-website/lab-test/lab-tests-1.webp",
      mobile: "/images/new-website/lab-test/lab-tests-1.webp",
    },
    {
      desktop: "/images/new-website/lab-test/lab-test-2.webp",
      mobile: "/images/new-website/lab-test/lab-test-2.webp",
    },
    {
      desktop: "/images/new-website/lab-test/lab-test-3.webp",
      mobile: "/images/new-website/lab-test/lab-test-3.webp",
    },
    {
      desktop: "/images/new-website/lab-test/lab-test-4.webp",
      mobile: "/images/new-website/lab-test/lab-test-4.webp",
    },
    {
      desktop: "/images/new-website/lab-test/lab-test-5.webp",
      mobile: "/images/new-website/lab-test/lab-test-5.webp",
    },
    {
      desktop: "/images/new-website/lab-test/lab-test-6.webp",
      mobile: "/images/new-website/lab-test/lab-test-6.webp",
    },
  ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What types of lab tests does Bridge Health offer?",
      answer:
        "Bridge Health offers a wide range of lab tests, including blood tests, urine tests, imaging tests, and advanced diagnostic panels for various health conditions.",
    },
    {
      question: "Does Bridge Health offer home sample collection for lab tests?",
      answer: "Yes, Bridge Health provides convenient home sample collection services for lab tests, ensuring comfort and accessibility for patients.",
    },
    {
      question: "Where will I receive my lab test reports from Bridge Health?",
      answer: "You will receive your lab test reports digitally via email or the Bridge Health app, ensuring quick and secure access.",
    },
    {
      question: "What are Bridge Health's routine tests?",
      answer: "Routine tests include complete blood counts (CBC), blood sugar levels, lipid profiles, thyroid tests, and general health check-up panels.",
    },
    {
      question: "What's the cost of lab tests in Bangalore?",
      answer: "The cost varies based on the test, starting from ₹200 for basic tests and going up to ₹10,000 for advanced diagnostics.",
    },
  ];


  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards
  const [hasAnimated, setHasAnimated] = useState([]); // Animation tracking for cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

      setHasAnimated((prev) =>
        updatedVisibility.map((visible, index) => prev[index] || visible)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardsData = [
    { title: "FullBody Checkup", image: "/images/full-body-checkup.webp" },
    { title: "Senior Health Checkup", image: "/images/senior-citizen.webp" },
    { title: "Women's Health", image: "/images/women-care-1.webp" },
    { title: "BH Health Packages", image: "/images/health-package.webp" },
    { title: "Diabetes Management", image: "/images/sugar-blood-test.webp" },
    { title: "Weigth Management", image: "/images/weight-management.webp" },
    { title: "Thyroid Tests", image: "/images/thyroid-test.webp" },
    { title: "Fever Tests", image: "/images/fever.webp" },
    { title: "Heart Checkup", image: "/images/heart-checkup.webp" },
    { title: "Kidney Checkup", image: "/images/kidney-test.webp" },
    { title: "Liver Function", image: "/images/liver-test.webp" },
    { title: "Bone Tests", image: "/images/bone-test.webp" },
    { title: "Blood Tests", image: "/images/blood-test.webp" },
    { title: "Hormone Checkup", image: "/images/hormone-test.webp" },
    { title: "Vitamin Tests", image: "/images/vitamin-test.webp" },
    { title: "Urine Tests", image: "/images/urine-test.webp" },
  ];

  const styles = {
    headingwoman1: {
        fontSize: "50px",
        color:"#0d0d0d",
        fontWeight: "600",
        lineHeight: "1.2",
        textAlign: "left",
        width: "100%"
      }
      
  };

  const tests = [
    {
      icon: "/images/blood-test.webp",
      title: "Complete Blood Count (CBC)",
      testsIncluded: "29 Parameters Included",
      price: "₹340",
      originalPrice: "₹510",
      discount: "23% off",
    },
    {
      icon: "/images/sugar-blood-test.webp",
      title: "HbA1c, Glycated Haemoglobin",
      testsIncluded: "3 Parameters Included",
      price: "₹580",
      originalPrice: "₹630",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "9 Parameters Included",
      price: "₹700",
      originalPrice: "₹740",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Kidney Function Test (LFT)",
      testsIncluded: "12 Parameters Included",
      price: "₹440",
      originalPrice: "₹740",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Lipid Profile",
      testsIncluded: "14 Parameters Included",
      price: "₹580",
      originalPrice: "₹810",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "ESR",
      testsIncluded: "29 Parameters Included",
      price: "₹140",
      originalPrice: "₹210",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Vitamin B12",
      testsIncluded: "29 Parameters Included",
      price: "₹1130",
      originalPrice: "₹1290",
      discount: "23% off",
    },
    {
    icon: "/images/kidney-test.webp",
    title: "Vitamin D",
    testsIncluded: "29 Parameters Included",
    price: "₹1650",
    originalPrice: "₹1880",
    discount: "23% off",
  },
  ];

  return (
    <div>
      <Header/>
      <div className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1 className="first-section-heading">
          Your Partner in{" "}
            <span className="highlight1">Trusted, Timely, and Accurate</span> Diagnosis for Health Monitoring
          </h1>
          <p className="paragraph-left">Book blood tests in Bangalore from Bridge Health’s NABL & ISO-certified labs</p>
          <button className="buttonlanding">Schedule Your Appointment</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/radiology/lab-test-bg.webp"
            alt="Woman smiling"
            className="imagelanding imageFadeInUp"
          />
        </div>
      </div>
      
            <section>
            <div className="rightstrongcontainer">
            <h2 className="heading1">
            Detect It Early: 
              <span className="highlight1"> Explore Our Diagnostic Services </span>
              <span className="line-break-heading"></span>
            </h2>
              <ServiceSection
                cardsData={cardsData}
                isMobile={isMobile}
                highlight="Diagnostic Services"
                heading="Detect It Early: Explore Our"
                customClass="WellnessSectionWomen"
              />
              </div>
            </section>

      <section>
        <div className={`strongcontainer ${isMobile ? "mobile" : ""}`}>
          <div className="rightstrongcontainer">
            <h2 className="heading1">
              Top Booked 
              <span className="highlight1"> Blood Tests</span>
              <span className="line-break-heading"><br /></span> At Home
            </h2>
            <div className="test-slider-container">
            <BloodTestSlider tests={tests} BloodTestsettings={BloodTestsettings} />
            </div>
          </div>
        </div>
      </section>
      
      <section>
        <div className={`aicontainer ${isMobile ? "mobile" : ""}`}>
          <div className="get-labcontainer">
            <h2 className="heading1">
            Get Lab Tests Done in just <br />
              <span className="highlight1"> 3 Simple Steps</span>
            </h2>
            
            <div className="get-lab-span">
              <span >Step 1</span>
              <hr className="get-lab-spanborder" />
              <span>Step 2</span>
              <hr className="get-lab-spanborder" />
              <span>Step 3</span>
            </div>

            <div className="get-lab-p">
              <div className="get-labpara1">
                <div>
                  <img src="/images/new-website/radiology/book-test.webp" alt="" width={170}/> 
                </div>
                <div>
                  <h1 className="get-lab-heading">Book Your Test</h1>
                  <p>Schedule home sample collection or walk into the nearest BH clinic.</p>
                </div>
              </div>
              <div className="get-labpara1">
              <div>
                  <img src="/images/home-collection-1.webp" alt=""  width={150}/> 
                </div>
                <div>
                  <h1 className="get-lab-heading">Give Your Sample</h1>
                  <p>Our trained representatives will assist your blood, urine, stool sample collection with utmost safety. </p>
                </div>
              </div>
              <div className="get-labpara1">
                <div>
                  <img src="/images/blood-test-report.webp" alt=""  width={110}/> 
                </div>
                <div>
                  <h1 className="get-lab-heading">Receive Your Reports</h1>
                  <p>Conveniently access digital reports or collect in person at the clinic.</p>
                </div>
              </div>
             
            </div>
          </div>
          
        </div>
      </section>
      <section className="nabl-iso">
        <div className="nabl-container">
          <h2 className="heading1">
            <span className="highlight1">NABL & ISO Certified Lab </span>
            at JP Nagar, Bangalore
          </h2>
          <p>Because You Deserve the Best Care with Timely Results </p>
        </div>
        <section style={styles.bannerSliderT1}>
          <SliderLab sliderSettings={sliderSettings} slides={slides} />
        </section>      
        </section>
        <section className="lab-test-callout">
          <div className="lab-test-callout-col">
            <h2>Book <span>Lab Test</span> with us</h2>
          </div>
          <div className="lab-test-callout-col">
            <div className="lab-test-callout-img">
              <img src="/images/new-website/radiology/medical-report.webp" alt="" />
            </div>
            <div className="lab-test-callout-details">
            <Counter targetValue={98} duration={7000} />
              <p>On-time report delivery</p>
            </div>
          </div>
          <div className="lab-test-callout-col">
          <div className="lab-test-callout-img">
              <img src="/images/hormone-test.webp" alt="" />
            </div>
            <div className="lab-test-callout-details">
            <Counter targetValue={97} duration={7000} />
              <p>Timely Sample Collection</p>
            </div>
          </div>
          <div className="lab-test-callout-col">
          <div className="lab-test-callout-img">
              <img src="/images/review.webp" alt="" />
            </div>
            <div className="lab-test-callout-details">
            <Counter targetValue={99} duration={7000} />
              <p>Positive Customer Reviews</p>
            </div>
          </div>
        </section>
      <section className="why-choose-section-radio">
          <h2 className="heading1">Why Choose <span className="highlight1">Bridge Health</span> for your <br/>Lab Tests?</h2>
                    
          <WhyLab
            whyData={whyData} 
            dynamicText="Radiology" 
            dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
          />
      </section>
    <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
        <Footer />
    </div>
  );
};

export default LabTestPage;
